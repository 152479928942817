@font-face {
  font-family: 'Glyphicons Halflings';
  src: url(./assets/fonts/glyphicons-halflings-regular.eot);
  src: url(./assets/fonts/glyphicons-halflings-regular.eot?#iefix)
      format('embedded-opentype'),
    url(./assets/fonts/glyphicons-halflings-regular.woff) format('woff'),
    url(./assets/fonts/glyphicons-halflings-regular.ttf) format('truetype'),
    url(./assets/fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular)
      format('svg');
}

@font-face {
  font-family: ArsMaquette-Light;
  font-weight: 300;
  font-style: normal;
  src: url(./assets/fonts/arsmaquettepro-light.eot);
  src: url(./assets/fonts/arsmaquettepro-light.eot?#iefix)
      format('embedded-opentype'),
    url(./assets/fonts/arsmaquettepro-light.woff) format('woff'),
    url(./assets/fonts/arsmaquettepro-light.ttf) format('truetype'),
    url(./assets/fonts/arsmaquettepro-light.svg#ArsMaquette) format('svg');
}

@font-face {
  font-family: ArsMaquette;
  font-weight: 500;
  font-style: normal;
  src: url(./assets/fonts/arsmaquettepro-regular.eot);
  src: url(./assets/fonts/arsmaquettepro-regular.eot?#iefix)
      format('embedded-opentype'),
    url(./assets/fonts/arsmaquettepro-regular.woff) format('woff'),
    url(./assets/fonts/arsmaquettepro-regular.ttf) format('truetype'),
    url(./assets/fonts/arsmaquettepro-regular.svg#ArsMaquette) format('svg');
}

@font-face {
  font-family: ArsMaquette;
  font-weight: 700;
  font-style: normal;
  src: url(./assets/fonts/arsmaquettepro-medium.eot);
  src: url(./assets/fonts/arsmaquettepro-medium.eot?#iefix)
      format('embedded-opentype'),
    url(./assets/fonts/arsmaquettepro-medium.woff) format('woff'),
    url(./assets/fonts/arsmaquettepro-medium.ttf) format('truetype'),
    url(./assets/fonts/arsmaquettepro-medium.svg#ArsMaquette) format('svg');
}

@font-face {
  font-family: ArsMaquette;
  font-weight: 900;
  font-style: normal;
  src: url(./assets/fonts/arsmaquettepro-bold.eot);
  src: url(./assets/fonts/arsmaquettepro-bold.eot?#iefix)
      format('embedded-opentype'),
    url(./assets/fonts/arsmaquettepro-bold.woff) format('woff'),
    url(./assets/fonts/arsmaquettepro-bold.ttf) format('truetype'),
    url(./assets/fonts/arsmaquettepro-bold.svg#ArsMaquette) format('svg');
}

@font-face {
  font-family: ARSMaquette-Black;
  font-weight: 400;
  font-style: normal;
  src: url(./assets/fonts/arsmaquettepro-black.eot);
  src: url(./assets/fonts/arsmaquettepro-black.eot?#iefix)
      format('embedded-opentype'),
    url(./assets/fonts/arsmaquettepro-black.woff) format('woff'),
    url(./assets/fonts/arsmaquettepro-black.ttf) format('truetype');
}

@font-face {
  font-family: HandOfSean;
  font-weight: 400;
  font-style: normal;
  src: url(./assets/fonts/handsean-webfont.eot);
  src: url(./assets/fonts/handsean-webfont.eot?#iefix)
      format('embedded-opentype'),
    url(./assets/fonts/handsean-webfont.woff) format('woff'),
    url(./assets/fonts/handsean-webfont.ttf) format('truetype'),
    url(./assets/fonts/handsean-webfont.svg#HandOfSean) format('svg');
}

@font-face {
  font-family: Glyphicons;
  font-weight: 400;
  font-style: normal;
  src: url(./assets/fonts/glyphicons-halflings-regular.eot);
  src: url(./assets/fonts/glyphicons-halflings-regular.eot?#iefix)
      format('embedded-opentype'),
    url(./assets/fonts/glyphicons-halflings-regular.woff) format('woff'),
    url(./assets/fonts/glyphicons-halflings-regular.ttf) format('truetype'),
    url(./assets/fonts/glyphicons-halflings-regular.svg#Glyphicons)
      format('svg');
}

@font-face {
  font-family: TWBT;
  font-weight: 400;
  font-style: normal;
  src: url(./assets/fonts/12wbt.eot);
  src: url(./assets/fonts/12wbt.eot?#iefix) format('embedded-opentype'),
    url(./assets/fonts/12wbt.woff) format('woff'),
    url(./assets/fonts/12wbt.ttf) format('truetype'),
    url(./assets/fonts/12wbt.svg#TWBT) format('svg');
}

@font-face {
  font-family: AboutFace;
  font-weight: 400;
  font-style: normal;
  src: url(./assets/fonts/aboutface.ttf);
}

@font-face {
  font-family: FontAwesome;
  font-weight: 400;
  font-style: normal;
  src: url(./assets/fonts/fontawesome-webfont.eot);
  src: url(./assets/fonts/fontawesome-webfont.eot?#iefix)
      format('embedded-opentype'),
    url(./assets/fonts/fontawesome-webfont.woff) format('woff'),
    url(./assets/fonts/fontawesome-webfont.ttf) format('truetype'),
    url(./assets/fonts/fontawesome-webfont.svg#FontAwesome) format('svg');
}

@font-face {
  font-family: TWBT;
  font-weight: 400;
  font-style: normal;
  src: url(./assets/fonts/12wbt.eot);
  src: url(./assets/fonts/12wbt.eot?#iefix) format('embedded-opentype'),
    url(./assets/fonts/12wbt.woff) format('woff'),
    url(./assets/fonts/12wbt.ttf) format('truetype'),
    url(./assets/fonts/12wbt.svg#TWBT) format('svg');
}

@font-face {
  font-family: '12WBT';
  src: url(./assets/fonts/12wbt-theme.eot?-qhch5k);
  src: url(./assets/fonts/12wbt-theme.eot?#iefix-qhch5k)
      format('embedded-opentype'),
    url(./assets/fonts/12wbt-theme.woff?-qhch5k) format('woff'),
    url(./assets/fonts/12wbt-theme.ttf?-qhch5k) format('truetype'),
    url(./assets/fonts/12wbt-theme.svg?-qhch5k#icomoon) format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'LGSmart-Regular';
  src: url(./assets/fonts/LGSmartFont/LG-Smart-Regular.otf) format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'LGSmart-Light';
  src: url(./assets/fonts/LGSmartFont/LG-Smart-Light.otf) format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'LGSmart-Bold';
  src: url(./assets/fonts/LGSmartFont/LG-Smart-Bold.otf) format("opentype");
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: ArsMaquette, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 500 !important;
  font-size: 14px;
  color: #3c444d;
  letter-spacing: -0.02em;
}

.font-light {
  font-family: ArsMaquette, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 300 !important;
}

.disclaimer {
  color: #3c444d;
  margin-top: 1em;

  a {
    color: inherit;
  }
}
